import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-account-enquiry',
  templateUrl: './account-enquiry.component.html',
  styleUrls: ['./account-enquiry.component.scss']
})
export class AccountEnquiryComponent implements OnInit {
  status = '';
  date_from = '';
  date_to = '';
  page_size = 10;
  isLoading = false;
  loading =false;
  loadSuccess=false;
  accountData :any;
  accountNumber:string = '';
  transactions: any;
  transactionsData: any;
  pay_date: any;
  validAccountNumber:any;
  showTransactions = false;
  itemID: any;
  repayment_amount: any;
  destroy$ = new Subject<boolean>();
  constructor(private service: AppServiceService, private toastr: ToastrService, titleService: Title) {
   }

  ngOnInit(): void {
  }

  pageSizeChanged() {
    this.getAccountEnquiry();
  }

  validateInternalAccount() {
    this.isLoading = true;
    this.showTransactions = false;
    console.log(this.accountNumber);
    this.service.validateInternalAccount(this.accountNumber).pipe(takeUntil(this.destroy$)).subscribe((data: any) => {
      this.isLoading = false;
      this.showTransactions = false;
      this.accountData = data.data;
      this.validAccountNumber = data.data.account_number;
      console.log(data);
      this.loadSuccess =true;
      this.loading = false;
    }, 
    error => {
            this.showTransactions = false;
            this.loading = true;
            this.isLoading = false;
            this.loadSuccess =false;
          }
    )
  }


  getAccountEnquiry() {
    this.isLoading = true;
    const params = {
      start_date: this.date_from,
      end_date: this.date_to,
      account_number: this.validAccountNumber,
    };
    this.service.getAccountEnquiry(params).subscribe(
      data => {
        this.showTransactions = true;
        this.isLoading = false;
        const result: any = data;
        if (result.status === '00') {
            this.transactionsData = result;
          this.transactions = result.data.transactions;
          console.log(this.transactions)
        } else {
          this.toastr.success(result['message'], '');
        }
      },
      error => {
        this.isLoading  = false;
      }
    );
  }

}
