<!-- <ngx-loading-bar color ="#161c4a"></ngx-loading-bar > -->
<div class="page-wrapper over">
	<ng-sidebar-container
			(onBackdropClicked)="_onBackdropClicked()">
		<ng-sidebar
			[(opened)]="_opened"
			[mode]="_MODES[_modeNum]"
			[keyClose]="_keyClose"
			[position]="_POSITIONS[_positionNum]"
			[dock]="_dock"
			[dockedSize]="'50px'"
			[autoCollapseHeight]="_autoCollapseHeight"
			[autoCollapseWidth]="_autoCollapseWidth"
			[closeOnClickOutside]="_closeOnClickOutside"
			[closeOnClickBackdrop]="_closeOnClickBackdrop"
			[showBackdrop]="_showBackdrop"
			[animate]="_animate"
			[trapFocus]="_trapFocus"
			[autoFocus]="_autoFocus"
			[sidebarClass]="'app-sidebar'"
			[ariaLabel]="'My sidebar'"
			(onOpenStart)="_onOpenStart()"
			(onOpened)="_onOpened()"
			(onCloseStart)="_onCloseStart()"
			(onClosed)="_onClosed()"
			(onTransitionEnd)="_onTransitionEnd()">
			<app-sidebar></app-sidebar>
		</ng-sidebar>
		
		<div ng-sidebar-content>
			<div class="sticky-top" >
				<app-header  (toggleSidebarEmit)="_toggleOpened()"></app-header>
			</div>
			<main class="page-content" >
				<router-outlet (activate)="onActivate($event)"></router-outlet>
				<!-- <app-footer></app-footer> -->
				<br>
				<br><br>
				<br><br>
			</main>
		</div>
	</ng-sidebar-container>
</div>