import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {AppServiceService} from '../../app-service.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  login: any;
  auth: Array<any> = [];
  public isCollapsed = true;
  @Output() toggleSidebarEmit: EventEmitter<String> = new EventEmitter<String>();
  constructor(private router: Router, private service: AppServiceService) { 
    // this.auth = this.service.getAuth();
    this.login = this.service.getUser();
  }

  toggleSidebar() {
    this.toggleSidebarEmit.emit();
  }
//   handleUser(){
//     const theLoggedUser = this.service.getUser()
//     console.log(theLoggedUser)
//     return theLoggedUser
// }

  logout() {
    // this.service.removeAuth();
    // this.auth = null;
    this.service.clearStorage().then(()=> this.router.navigate(['/account/login']))
    
  }

  ngOnInit() {
  }
}
