import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { AuthGuard } from './../auth/auth-guard.service';
import { ChartComponent } from './chart/chart.component';
import { DialogComponent } from './dialog/dialog/dialog.component';
import { WaiveDefaultComponent } from './waive-default/waive-default.component';
import { DueRepaymentReportComponent } from './due-repayment-report/due-repayment-report.component';
import { AccountEnquiryComponent } from './account-enquiry/account-enquiry.component';
import { InvestmentsTeamComponent } from './investments-team/investments-team.component';
import { TransactionJournalComponent } from './transaction-journal/transaction-journal.component';

const routes: Routes = [
	{
		path: 'account',
		children: [
			{
				path: 'login',
				loadChildren: () => import('./account/login-page/login-page.module').then(m => m.LoginPageModule)
			},
			{
				path: 'forgot-password',
				loadChildren: () => import('./account/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
			}
		]
	},
	{
		path: '',
		component: HomeLayoutComponent,
		children: [
			{
				path: '',
				redirectTo: 'dashboard',
				// tslint:disable-next-line: indent
				pathMatch: 'full'
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [AuthGuard],
				data: { preload: true },
			},
			{
				path: 'users',
				loadChildren: () => import('./users-page/users-page.module').then(m => m.UsersPageModule),
				data: { preload: true }
			},
			{
				path: 'loans',
				children: [
					{
						path: ':id',
						loadChildren: () => import('./loans-page/loans-page.module').then(m => m.LoansPageModule),
						data: { preload: true }
					},
					
					{
						path: 'view/:id',
						loadChildren: () => import('./single-loan-page/single-loan-page.module').then(m => m.SingleLoanPageModule),
						pathMatch: 'full',
					},
				]
			
			},
			{
				path: 'all-customers',
				loadChildren: () => import('./customers-account/all-customers/all-customers.module').then(m => m.AllCustomersModule),
				data: { preload: true }
				// children: [
				// 	{
				// 		path: 'view/:id',
				// 		loadChildren: () => import('./single-investment/single-investment.module').then(m => m.SingleInvestmentModule)
				// 	},
				// ]
			},
			{
				path: 'create-customer',
				loadChildren: () => import('./customers-account/create-customer/create-customer.module').then(m => m.CreateCustomerModule),
				data: { preload: true }
			},
			{
				path: 'chart',
				component: ChartComponent,
				data: { preload: true }
			},
			{
				path: 'waive-default',
				component: WaiveDefaultComponent,
				canActivate: [AuthGuard]
			},		
			{
				path: 'account-enquiry',
				component: AccountEnquiryComponent,
				canActivate: [AuthGuard],
			},		
			{
				path: 'add-newgl',
				component: DialogComponent,
				data: { preload: true }
			},
			{
				path: 'investments',
				children: [
					{
						path: 'create-investment',
						loadChildren: () => import('./utilities/investment-stepper/investment-stepper.module').then(m => m.InvestmentStepperModule)
					},
					{
						path: 'view/:id',
						loadChildren: () => import('./single-investment/single-investment.module').then(m => m.SingleInvestmentModule)
					},

					{
						path: 'team',
						component: InvestmentsTeamComponent,
					},
					
					{
						path: 'manage',
						loadChildren: () => import('./investments-management/investments-management.module').then(m => m.InvestmentsManagementModule)
					},
					{
						path: ':id',
						loadChildren: () => import('./all-investments/all-investments.module').then(m => m.AllInvestmentsModule)
					}
				]
			},
			{
				path: 'collateral-loan',
				children: [
					{
						path: 'categories',
						loadChildren: () => import('./collateral-loan/categories/categories.module').then(m => m.CategoriesModule)
					},
					{
						path: 'agent',
						loadChildren: () => import('./collateral-loan/agent/agent.module').then(m => m.AgentModule),
					},
					{
						path: 'rate',
						loadChildren: () => import('./collateral-loan/rate/rate.module').then(m => m.RateModule)
					},
					{
						path: 'loans',
						loadChildren: () => import('./collateral-loan/collateral-loan-customers/collateral-loan-customers.module').then(m => m.CollateralLoanCustomersModule)
					},
				],
			},
			{
				path: 'profile',
				loadChildren: () => import('./profile-page/profile-page.module').then(m => m.ProfilePageModule),
				data: { preload: true },
				canActivate: [AuthGuard]
			},
			{
				path: 'change-password',
				loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule)
			},
			{
				path: 'report',
				children: [
					{
						path: 'loans',
						loadChildren: () => import('./loans-report/loans-report.module').then(m => m.LoansReportModule)
					},
					{
						path: 'repayment',
						loadChildren: () => import('./repayment-report/repayment-report.module').then(m => m.RepaymentReportModule)
					},
					{
						path: 'defaults',
						loadChildren: () => import('./defaults-report/defaults-report.module').then(m => m.DefaultsReportModule)
					},
					{
						path: 'active-loans',
						loadChildren: () => import('./active-loans-report/active-loans-report.module').then(m => m.ActiveLoansReportModule)
					},
					{
						path: 'users-signup',
						loadChildren: () => import('./users-signup-report/users-signup-report.module').then(m => m.UsersSignupReportModule)
					},
					{
						path: 'due-repayment',
						component: DueRepaymentReportComponent,
					},
					{
						path: 'transaction-journal',
						component: TransactionJournalComponent,
					},
				]
			},
			{
				path: 'admins',
				loadChildren: () => import('./users-management-page/users-management-page.module').then(m => m.UsersManagementPageModule)
			},
			{
				path: 'utilities',
				children: [
					{
						path: 'upload-loans',
						loadChildren: () => import('./utilities/upload-loan-records/upload-loan-records.module').then(m => m.UploadLoanRecordsModule)
					},
					{
						path: 'calculate-repayment',
						loadChildren: () => import('./utilities/calculate-repayment/calculate-repayment.module').then(m => m.CalculateRepaymentModule)
					},
					{
						path: 'add-loan',
						loadChildren: () => import('./utilities/loan-stepper/loan-stepper.module').then(m => m.LoanStepperModule)
					},
					{
						path: 'add-investment',
						loadChildren: () => import('./utilities/investment-stepper/investment-stepper.module').then(m => m.InvestmentStepperModule)
					},
					{
						path: 'loan-products',
						loadChildren: () => import('./utilities/loan-products/loan-products.module').then(m => m.LoanProductsModule)
					},
					{
						path: 'offer-letter',
						loadChildren: () => import('./utilities/offer-letter/offer-letter.module').then(m => m.OfferLetterModule)
					}
				]
			},
			{
				path: 'funds-transfer',
				loadChildren: () => import('./funds-transfer/funds-transfer.module').then(m => m.FundsTransferModule)
			},
		]
	},
	{
		path: '**',
		redirectTo: 'dashboard',
		pathMatch: 'full'
	},

];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [AuthGuard]
})
export class AppRoutingModule { }
