<br>
<br>
<!-- Loan Section -->
<div class="section no-padding" style="height: auto; background-color: none !important;">
    <div class="container-fluid content">
        <div class="col-md-12 col-sm-12">
            <div class="card stats-card" style="border-radius: 10px;">
                <div class="card-body" style="min-height: 200px; position: relative;">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="text-primary m-0"><strong>Direct Debit</strong></h5>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col">
                            <div>
                                <label class="text-muted" for="">Start Date</label>
                                <input type="date" class="form-control form-control-sm" name="start"
                                    [(ngModel)]="date_from">
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <label class="text-muted" for="">End Date</label>
                                <input type="date" class="form-control form-control-sm" name="start"
                                    [(ngModel)]="date_to">
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <label class="text-muted" for="">Status</label>
                                <select type="text" name="status" [(ngModel)]="status" class="form-control form-control-sm">
                                    <option selected value="all">All</option>
                                    <option value="unpaid">unpaid</option>
                                    <option value="paid">paid</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-sm-12 col-md-4">
                            <div>
                                <!-- <p class="text-sm form-inline">Show &nbsp;&nbsp;
                                    <select class="custom-select custom-select-sm" [(ngModel)]="page_size"
                                        (change)="pageSizeChanged()">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                    </select> &nbsp;&nbsp; entries
                                </p> -->
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8">
                            <div class="float-right">
                                <!-- <button class="btn btn-primary mr-1" (click)="getRepaymentReport()">Export</button> -->
                                <button class="btn btn-primary" (click)="getRepaymentReport()">Get Report</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center" *ngIf="loading" class="loading">
                        <img src="assets/imgs/loading.gif" height="100">
                    </div>

                    
                    <span *ngIf="reportsData">
                        <div *ngIf="reports.length > 0">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped" style="min-height: 30vh;">
                                  <thead class="text-sm">
                                    <tr class="text-blue-badge">
                                      <th>Name</th>
                                      <th>Amount Due</th>
                                      <th>Amount Paid</th>
                                      <th>Due Date</th>
                                      <th>RRR</th>
                                    </tr>
                                  </thead>
                                  <tbody class="text-sm">
                                    <tr *ngFor="let report of reports;">
                                      <td width="15%">
                                        <span class="text-blue-badge">{{report.user?.firstname | titlecase}} {{report.user?.lastname | titlecase}}</span>
                                      </td>
                                      <td width="10%">
                                        <span class="text-blue-badge">{{report.amount | currency:'₦': 'symbol':'1.2'}}</span>                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{report.amount_paid | currency:'₦': 'symbol':'1.2'}}</span>
                                      </td>
                                      <td width="20%">
                                        <span class="text-blue-badge">{{report.due_date | date}}</span>
                                      </td>
                                      <td width="20%">
                                        <span class="text-blue-badge">{{report.mandate_reference? report.mandate_reference:'N/A'}}</span>
                                      </td>
                                      <!-- <td>
                                        <span *ngIf="report.status == 0" class="badge badge-secondary">Not Paid</span>
                                        <span *ngIf="report.status == 1" class="badge badge-primary">Half Paid</span>
                                        <span *ngIf="report.status == 2" class="badge badge-success">Fully Paid</span>
                                        <span *ngIf="report.status == 3" class="badge badge-info">Overpaid</span>
                                        <span *ngIf="report.status == 4" class="badge badge-dark">Refund</span>
                                    </td> -->
                                      <!-- <td>
                                        <ng-container *ngIf="report.status >= 1">
                                            <span class="text-blue-badge">{{report.payment_date | date}}</span>
                                        <br>
                                        </ng-container>
                                        <div *ngIf="report.status == 0 || report.status == 1">
                                            <button class="btn btn-secondary mr-1 btn-sm" data-toggle="modal"
                                            data-target="#confirmRepaymentModal" (click)="setRepaymentObject(report.id, report.amount)">Confirm</button>
                                            <button class="btn btn-danger btn-sm" (click)="deleteRepayment(report.id)">Delete</button>
                                        </div>
                                      </td> -->
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              

                            <br>
                            <div class="float-right">
                                <button (click)="goPrevious()" class="btn shadow  btn-sm">
                                    <i class="fa fa-angle-double-left"></i>&nbsp;&nbsp;Previous
                                </button>
                                <button (click)="goNext()" class="btn shadow  ml-4 btn-sm">
                                    Next &nbsp;&nbsp;<i class="fa fa-angle-double-right"></i>
                                </button>
                            </div>
                        </div>
                        <div *ngIf="reports.length == 0" style="margin-top: 40px; margin-bottom: 40px;">
                            <p class="text-center text-blue-black"><b>No data available</b></p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
