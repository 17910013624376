
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AppServiceService {
    constructor(private http: HttpClient) {

    }

    fetchMoreRecords(url, data) {
        return this.http.post(url, data);
    }

    getAuth() {
        // return this.localStorageService.get("auth");
        // let auth = sessionStorage.getItem("admin");
        // this.auth = JSON.parse(auth);

        // this.setAuthHttpHeader(this.auth);

        // return this.auth;
    }

    setAuth(auth: any) {
        //this.localStorageService.set("auth", this.result);
    }

    async clearStorage() {
        await window.localStorage.removeItem('mm-admin-token');
        await window.localStorage.removeItem('mm-admin');
    }

    logUserOut() {
        return this.clearStorage();
    }

    removeAuth() {
        sessionStorage.removeItem("admin");
        //this.localStorageService.set("auth", this.result);
    }

    setToken(token) {
        return localStorage.setItem('mm-admin-token', JSON.stringify(token));
    }
    getToken() {
        const token = localStorage.getItem('mm-admin-token')
        if (token) {
            return JSON.parse(localStorage.getItem('mm-admin-token'))
        } else {
            return null;
        }
    }

    setUser(user: any) {
        return localStorage.setItem('mm-admin', JSON.stringify(user))
    }

    getUser() {
        const user = localStorage.getItem('mm-admin')
        if (user) {
            return JSON.parse(localStorage.getItem('mm-admin'))
        } else {
            return null;
        }
    }



    login(params) {
        const body = JSON.stringify(params);
        return this.http.post(environment.apiEndpoint + 'admin/account/login', body);
    }
    resetPassword(params) {
        const body = JSON.stringify(params);
        return this.http.post(environment.apiEndpoint + 'admin/account/password/reset', body);
    }

    changeAccountPassword(params) {
        const body = JSON.stringify(params);
        return this.http.post(environment.apiEndpoint + 'admin/account/password/change', body);

    }
    // customers
    createCustomer(params:any) {
        return this.http.post(environment.apiEndpoint + 'v2/customers/create', params);
    }
    fetchAllCustomers(params: any) {
        return this.http.get(`${environment.apiEndpoint}v2/customers/get-customers?search_text=${params}`);
    }
    fetchAdminUser() {
        return this.http.get(`${environment.apiEndpoint}v2/fetch/admin-users`);
    }
    fetchSingleCustomers(user_id:any) {
        return this.http.post(`${environment.apiEndpoint}v2/customers/get-customers/single`, user_id);
    }

    getLoans(params) {
        return this.http.post(environment.apiEndpoint + 'loan/list', params);
    }
    getCollateralCategories() {
        return this.http.get(environment.apiEndpoint + 'v2/collateral/categories');
    }
    editCollateralCategories(id) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/categories/edit', {id});
    }
    createCollateralCategories(params) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/categories/create', params);
    }
    getSectors() {
        return this.http.get(environment.apiEndpoint + 'v2/utilities/sectors');
    }
    deleteCollateralCategories(params) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/categories/delete', params);
    }
    createCollateralRate(params) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/interest-grade/create', params);
    }
    getCollateralRate() {
        return this.http.get(environment.apiEndpoint + 'v2/collateral/interest-grade');
    }
    getCollateralAgent(status: any) {
        return this.http.get(environment.apiEndpoint + `v2/collateral/agents?category_id=${status}`);
    }
    downlaodLoneList(status: any) {
        return this.http.get(environment.apiEndpoint + `report/defaults?category_id=${status}`);
    }
    getCollateralLoansList(status: any) {
        return this.http.get(environment.apiEndpoint + `v2/collateral/loans?status=${status}`);
    }
    getCollateralLoansDetails(status: any) {
        return this.http.get(environment.apiEndpoint + `v2/collateral/loans/details?loan_id=${status}`);
    }
    editCollateralAgent(id) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/agents/edit', {id});
    }
    createCollateralAgent(params:any) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/agents/create', params);
    }
    
    declineCollateralLoan(params:any) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/loans/underwriting/decline', params);
    }
    cancelCollateralLoan(params:any) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/loans/underwriting/cancel', params);
    }
    disburseCollateralLoan(params:any) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/loans/disburse', params);
    }
    approveCollateralLoan(params:any) {
        return this.http.post(environment.apiEndpoint + 'v2/collateral/loans/underwriting/approve', params);
    }

    getLoanList(status: any) {
        return this.http.get(`${environment.apiEndpoint}loan/loan-list/download?status=${status}`);
    }
    loanDefaultCategories() {
        return this.http.get(`${environment.apiEndpoint}v2/loan/default/categories`);
    }

    getSingleLoan(loanId?: string) {
        return this.http.post(environment.apiEndpoint + 'loan/one', {
            loan_id: loanId,
        });
    }

    getCreditBureauReport(params) {
        return this.http.get(`${environment.apiEndpoint}loan/crb/credit-report?loan_id=${params.loan_id}&bvn=${params.bvn}`);
    }

    getSingleUser(authid?: string) {
        return this.http.post(environment.apiEndpoint + 'customers/one', {
            auth_id: authid,
        });
    }

    getUsers(params) {
        return this.http.post(environment.apiEndpoint + 'customers', params);
    }


    // getUser(user_id) {
    //     return this.http.get(environment.apiEndpoint + 'auth/users/' + user_id);
    // }

    rejectLoan(data) {
        return this.http.post(environment.apiEndpoint + 'loan/reject', data);
    }

    cancelLoan(data) {
        return this.http.post(environment.apiEndpoint + 'loan/cancel', data);
    }

    performUnderWriting(loan_id) {
        return this.http.post(environment.apiEndpoint + 'loan/perform/underwriting', { loan_id });
    }

    mmoveForDisburse(params) {
        return this.http.post(environment.apiEndpoint + 'loan/disburse/move', params);
    }
    moveForDisburseWithoutPay(params) {
        return this.http.post(environment.apiEndpoint + 'loan/disburse/move/nopay', params);
    }

    completeLoan(loan_id) {
        return this.http.post(environment.apiEndpoint + 'loan/complete', { loan_id });
    }

    liquidateLoan(loan_id) {
        return this.http.post(environment.apiEndpoint + 'loan/liquidate', { loan_id });
    }

    approveLoan(details) {
        return this.http.post(environment.apiEndpoint + 'loan/approval/initial', details);
    }

    editLoan(details) {
        return this.http.post(environment.apiEndpoint + 'loan/edit/loan', details);
    }

    finalApproveLoan(details) {
        return this.http.post(environment.apiEndpoint + 'loan/approval/final', details);
    }

    getProfile() {
        return this.http.get(environment.apiEndpoint + 'auth');
    }

    updateProfile(data) {
        return this.http.post(environment.apiEndpoint + 'auth/update', data);
    }

    changePassword(data) {
        return this.http.post(environment.apiEndpoint + 'auth/password/update', data);
    }

    changeProfilePicture(data) {
        return this.http.post(environment.apiEndpoint + 'auth/display/update', data);
    }

    fetchDashboard() {
        return this.http.get(environment.apiEndpoint + 'loan/dashboard');
    }
    waiveDefault() {
        return this.http.get(environment.apiEndpoint + 'loan/repayment/pending-waiver');
    }

    addRepayment(details) {
        return this.http.post(environment.apiEndpoint + 'loan/repayment/add', details);
    }

    uploadLoanDocument(details) {
        return this.http.post(environment.apiEndpoint + 'loan/document/upload', details);
    }

    uploadInvestmentDocument(details) {
        return this.http.post(environment.apiEndpoint + 'investment/document/upload', details);
    }
    confirmRepayment(details) {
        return this.http.post(environment.apiEndpoint + 'loan/repayment/confirm', details);
    }
    approveWaiver(details) {
        return this.http.post(environment.apiEndpoint + 'loan/repayment/approve-waiver', details);
    }
    declineWaiver(details) {
        return this.http.post(environment.apiEndpoint + 'loan/repayment/decline-waiver', details);
    }

    performChargeBack(details) {
        return this.http.post(environment.apiEndpoint + 'loan/paystack/charge', details);
    }

    applyForTopUp(details) {
        return this.http.post(environment.apiEndpoint + 'application/return/topup/submit', details);
    }

    deleteRepayment(details) {
        return this.http.post(environment.apiEndpoint + 'loan/repayment/delete', details);
    }

    getLoansReport(params) {
        return this.http.post(environment.apiEndpoint + 'report/loan', params);
    }

    getUsersSignupReport(params) {
        // return this.http.post(environment.apiEndpoint + 'report/loan', params);
        return window.open(`${environment.apiEndpoint}loan/users/report?start=${params.start}&end=${params.end}`)
    }

    getActiveReport(params) {
        // return this.http.post(environment.apiEndpoint + 'report/loan', params);
        return window.open(`${environment.apiEndpoint}loan/active/report?start=${params.start}&end=${params.end}`)
    }
    dueRepaymentReport(params) {
        // return this.http.post(environment.apiEndpoint + 'report/loan', params);
        return this.http.get(`${environment.apiEndpoint}report/due-repayment?start_date=${params.start}&end_date=${params.end}&status=${params.status}&export`)
    }
    

    getDefaultsReport(params) {
        return this.http.post(environment.apiEndpoint + 'report/defaults', params);
    }

    getRepaymentReport(params) {
        return this.http.post(environment.apiEndpoint + 'report/repayment', params);
    }
    getAccountEnquiry(params:any) {
        return this.http.post(`${environment.apiEndpoint}v2/accounts/account-enquiry`, params);
    }

    isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnLoan(key)) {
                return false;
            }
        }
        return true;
    }

    getAdminUsers(page_size?: number, searchText?: string, investment?: string) {
        return this.http.post(environment.apiEndpoint + 'admin/account/list', {
            page_size,
            search_text: searchText,
            investment
        });
    }
    getInvestmentStaff() {
        return this.http.get(`${environment.apiEndpoint}v2/investments/get-investment-staff`)
    }
    getInvestmentTeamlist() {
        return this.http.get(`${environment.apiEndpoint}v2/investments/get-investment-team/list`)
    }

    createInvestmentTeam(params:any) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/create-team`, params);
    }
    editAdminUser(params) {
        return this.http.post(environment.apiEndpoint + 'admin/account/edit', params);
    }

    viewAdminUser(params) {
        return this.http.post(environment.apiEndpoint + 'admin/account/one', params);
    }

    deleteAdminUser(params) {
        return this.http.post(environment.apiEndpoint + 'admin/account/delete', params);
    }

    toggleAdminAsSales(authid) {
        return this.http.post(environment.apiEndpoint + 'admin/account/sales/toggle', {authid});
    }

    toggleAdminAsInvestmentMarketer(authid) {
        return this.http.post(environment.apiEndpoint + 'admin/account/investment/toggle', {authid});
    }

    createAdminUser(params) {
        return this.http.post(environment.apiEndpoint + 'admin/account/create', params);
    }

    checkRemitaStatus(params) {
        return this.http.post(environment.apiEndpoint + 'loan/mandate/status', params);
    }

    viewRemitaHistory(params) {
        return this.http.post(environment.apiEndpoint + 'loan/mandate/payment/history', params);
    }

    stopRemitaMandate(params) {
        return this.http.post(environment.apiEndpoint + 'loan/mandate/stop', params);
    }

    uploadLoanRecords(data) {
        return this.http.post(environment.apiEndpoint + 'loan/manual/upload', data);
    }


    calculateRepayment(data) {
        return this.http.post(environment.apiEndpoint + 'loan/repayment/calculate', data);
    }


    // getInvestments(params) {
    //     return this.http.post(environment.apiEndpoint + 'investment/list', params);
    // }
    getInvestments(params) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/get-investments`, params);
    }

    getInvestmentTransactions(params) {
        return this.http.post(`${environment.apiEndpoint}investment/transaction/list`, params);
    }

    payInvestments(params) {
        return this.http.post(`${environment.apiEndpoint}investment/transaction/pay`, params);
    }

    fetchOneInvestment(id) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/single-investment`, id);
    }

    editInvestment(investment) {
        return this.http.post(`${environment.apiEndpoint}investment/edit`, investment);
    }

    processInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}investment/process-documents`, data);
    }
    createInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/book-investment`, data);
      }
    completeInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}investment/complete`, data);
      }

      confirmInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}investment/confirm/payment`, data);
      }
      

      cancelInvestment(id) {
        return this.http.post(`${environment.apiEndpoint}investment/cancel`, id);
      }
      declineInvestment(params) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/decline-investment`, params);
      }

      rolloverInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/rollover-investment`, data);
      }
      topupInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/topup-investment`, data);
      }

        liquidateInvestment(data) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/liquidate-investment`, data);
    }

      closeInvestment(id) {
        return this.http.post(`${environment.apiEndpoint}investment/close`, id);
      }

      getLoanProducts() {
        return this.http.get(`${environment.apiEndpoint}loan/products/get-products`);
    }
    getCustomersId(params:any) {
        return this.http.get(`${environment.apiEndpoint}v2/customers/validate_by_id?customer_id=${params}`);
    }
    validateInternalAccount(params:any) {
        return this.http.get(`${environment.apiEndpoint}v2/customer/wallet/validate-internal-account?account=${params}`);
    }
    approveInvestment(params) {
        return this.http.post(`${environment.apiEndpoint}v2/investments/approve-investment`, params);
    }

    createLoanProduct(params) {
        return this.http.post(`${environment.apiEndpoint}loan/products/create`, params);
      }

      deleteLoanProduct(id: string) {
        return this.http.get(`${environment.apiEndpoint}loan/products/delete/${id}`);
      }

      generateOfferLetter(params) {
        return this.http.post(`${environment.apiEndpoint}loan/offer-letter/create`, params);
      }

      loanManualUpload(params) {
        return this.http.post(`${environment.apiEndpoint}loan/manual/upload`, params);
      }

      investmentManualUpload(params) {
        return this.http.post(`${environment.apiEndpoint}investment/account/start/initiate`, params);
      }

      getChartOfAccounts() {
        return this.http.get(`${environment.apiEndpoint}finance/gls`);
      }
      addNewGl(data: any) {
        return this.http.post(`${environment.apiEndpoint}finance/add-new-gl`, data);
      }

      // funds transfer
      validateFundTransferAccount(account_number: string) {
        return this.http.post(`${environment.apiEndpoint}fund-transfer/validate-account`, {account_number}).pipe(map((data: any) => data.data));
      }

      handleFundTransfer(data: any) {
        return this.http.post(`${environment.apiEndpoint}v2/accounts/fund-transfer/intra`, data);
      }
//   makeInvestmentReferral(id) {
//     return this.http.post(`${environment.apiEndpoint}investment/referral/payment/initiate`, id);
//   }
}

