import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppServiceService } from './app-service.service';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private service: AppServiceService, private activatedRoute: ActivatedRoute, private toastr: ToastrService, private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const routePath = this.activatedRoute.snapshot['_routerState'].url;
        let headers = req.headers;
        headers = headers.set('Content-Type', 'application/json');
        const token = this.service.getToken();
        if (token && !!token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        } if (req.url.includes('approval/final') || req.url.includes('v2/collateral/agents/create') || req.url.includes('v2/customers/create') || req.url.includes('details/employment') || req.url.includes('loan/document/upload') || req.url.includes('initiate') || req.url.includes('investment/document/upload')) {
            headers = headers.delete('content-type');
        } else {
            headers = headers.set('Content-Type', 'application/json');
        }
        const authReq = req.clone({ headers });
        this.toggleAllButtonsDisable(true);
        return next.handle(authReq).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.toggleAllButtonsDisable(false);
                console.log(event.body);
                if (event.body.status === 'success'|| '00') {
                    // this.notification.success('Successful', event.body.message);
                } else {
                    this.toastr.error(event.body.message);
                }
            }
            return event;
        }), catchError((error: HttpErrorResponse) => {
            this.toggleAllButtonsDisable(false);
            const errorStatus = error.status;
            console.log(error);
            if (errorStatus === 401) {
                if (routePath.toLowerCase().includes('login')) {
                    this.toastr.error(error.error.message || error.message);
                } else {
                    this.toastr.error('Session Expired. Please login to continue');
                    this.service.clearStorage().then(() =>{
                    location.replace('/account/login')
                    });
                }
            } else if (errorStatus === 500) {
                this.toastr.error('Error connecting to server, please try again or contact support');
            } else {
                this.toastr.error(error.error.message || error.message);
            }
            return throwError(error);
        }));
    }

    private toggleAllButtonsDisable(value) {
        const buttons: any = document.getElementsByTagName('button');
        for (const button of buttons) {
            button.disabled = value;
        }
    }
}