<br>
<br>
<!-- Loan Section -->
<div class="section no-padding" style="height: auto; background-color: none !important;">
    <div class="container-fluid content">
        <div class="col-md-12 col-sm-12">
            <div class="card stats-card" style="border-radius: 10px;">
                <div class="card-body" style="min-height: 200px; position: relative;">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="text-primary m-0"><strong>Account Enquiry</strong></h5>
                    </div>
                    <hr>
                    <br>
                    <div class="">
                        <label for="investmentType">Validate Account Number</label>
                        <div class="row ">
                            <div class="input-group col-md-6">
                                <input type="text" name="customersId" maxlength="10"  (change)="validateInternalAccount()" [(ngModel)]="accountNumber" class="form-control"
                                 placeholder="Enter Account Number" />
                            </div>
                            <div class="col-md-6">
                            </div>
                            <ng-container>
                                <span *ngIf="loadSuccess == true"
                                   id="customersId" class="text-s ml-3 mt-1 text-success">{{accountData?.account_name}} <i class="fa fa-check-circle text-success"></i></span>
                                <span *ngIf="loading == true"
                                    class="text-xs ml-3 mt-1 text-danger">Account not found <i class="fa fa-times-circle text-danger"> </i></span>
                            </ng-container>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <div>
                                <label class="" for="">Start Date</label>
                                <input type="date" class="form-control " name="start"
                                    [(ngModel)]="date_from">
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <label class="" for="">End Date</label>
                                <input type="date" class="form-control " name="start"
                                    [(ngModel)]="date_to">
                            </div>
                        </div>
                    </div>
                    <div class="row m-1 mt-3">
                        <div class="">
                            <div class="">
                                <!-- <button class="btn btn-primary mr-1" (click)="getRepaymentReport()">Export</button> -->
                                <button class="btn btn-primary" *ngIf="loadSuccess == true" (click)="getAccountEnquiry()">Submit</button>
                                <button class="btn btn-primary" *ngIf="loadSuccess == false" disabled >Submit</button>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="text-center" *ngIf="isLoading" class="loading">
                        <img src="assets/imgs/loading.gif" height="100">
                    </div>

                    
                    <span *ngIf="transactionsData">
                        <div *ngIf="transactions.length > 0">
                            <div *ngIf="showTransactions;" class="table-responsive">
                                <label for="investmentType">Transactions</label>
                                <table class="table table-bordered table-striped" style="min-height: 30vh;">
                                  <thead class="text-sm">
                                    <tr class="text-blue-badge">
                                      <th>Transaction ID</th>
                                      <th>Transaction Date</th>
                                      <th>Value Date</th>
                                      <th>Details</th>
                                      <th>Credits</th>
                                      <th>Debits</th>
                                      <th>Balance</th>
                                    </tr>
                                  </thead>
                                  <tbody class="text-sm">
                                    <tr *ngFor="let transaction of transactions;">
                                      <td width="15%">
                                        <span class="text-blue-badge">{{transaction?.reference_no}}</span>
                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{transaction?.transaction_date}}</span>                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{transaction?.value_date}}</span>
                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{transaction?.details | titlecase}}</span>
                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{transaction?.transaction_type == 'C'? (transaction?.amount | currency:'₦'):''}}</span>
                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{transaction?.transaction_type == 'D'? (transaction?.amount | currency:'₦'):''}}</span>
                                      </td>
                                      <td width="15%">
                                        <span class="text-blue-badge">{{(transaction?.balance? transaction?.balance  : 0) | currency:'₦'}}</span>
                                      </td>
                                      <!-- <td>
                                        <span *ngIf="report.status == 0" class="badge badge-secondary">Not Paid</span>
                                        <span *ngIf="report.status == 1" class="badge badge-primary">Half Paid</span>
                                        <span *ngIf="report.status == 2" class="badge badge-success">Fully Paid</span>
                                        <span *ngIf="report.status == 3" class="badge badge-info">Overpaid</span>
                                        <span *ngIf="report.status == 4" class="badge badge-dark">Refund</span>
                                    </td> -->
                                      <!-- <td>
                                        <ng-container *ngIf="report.status >= 1">
                                            <span class="text-blue-badge">{{report.payment_date | date}}</span>
                                        <br>
                                        </ng-container>
                                        <div *ngIf="report.status == 0 || report.status == 1">
                                            <button class="btn btn-secondary mr-1 btn-sm" data-toggle="modal"
                                            data-target="#confirmRepaymentModal" (click)="setRepaymentObject(report.id, report.amount)">Confirm</button>
                                            <button class="btn btn-danger btn-sm" (click)="deleteRepayment(report.id)">Delete</button>
                                        </div>
                                      </td> -->
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              

                            <br>
                        </div>
                        <div *ngIf="transactions.length == 0" style="margin-top: 40px; margin-bottom: 40px;">
                            <p class="text-center text-blue-black"><b>No data available</b></p>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

