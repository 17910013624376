<div>
  <div class="sidebar-header nav-bar sticky-top affix">
    <a style=" text-decoration: none !important;">
      <img class="" src="assets/imgs/logo.png"/>
    </a>
  </div>
  <div style="background-color:#252A58" class="h-100 overflow-auto">
    <hr class="mt-0">
    <!-- <hr class="mt-0">
    <div class="sidebar-profile">
      <div class="user-pic">
        <img class="img-responsive img-preview" [src]="auth.user.display_picture ? auth.user.display_picture : 'assets/imgs/user.jpg'" alt="User picture" style="border-radius: .35rem !important;" width="40" height="40">
      </div>
      <div class="user-info">
        <span class="user-name text-white"><b>{{auth.user.first_name}} {{auth.user.last_name}}</b>
        </span>
        <span class="user-role text-white">{{auth.user.department}}</span>
      </div>
    </div>
    <hr> -->
    <div class="sidebar-menu">
      <!-- <div class="text-blue-badge text-uppercase px-3 px-lg-3 py-1 font-weight-bold small headings-font-family">MAIN</div> -->
      <!-- Close sidebar when item is clicked in smaller screens -->
      <ul class="sidebar-menu list-unstyled d-block d-sm-none">
        <li class="sidebar-list-item">
          <span class="text-muted">Loans</span>
        </li>
        <li class="sidebar-list-item"><a closeSidebar routerLink="/loans" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-money-bill mr-3"></i><span>Loans</span></a></li>
        <!-- <li class="sidebar-list-item"><a closeSidebar routerLink="/users" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-users mr-3"></i><span>Users</span></a></li> -->
        <li class="sidebar-list-item"><a closeSidebar routerLink="/admins" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-cogs mr-3"></i><span>Users Management</span></a></li>
      </ul>
    
      <!-- Do not close sidebar when item is clicked in smaller screens -->
      <ul class="sidebar-menu list-unstyled d-none d-sm-block">
        <li class="sidebar-heading text-light-gray  ">
          Main
        </li>
        <li class="sidebar-list-item"><a routerLink="/dashboard" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-info-circle mr-3"></i><span>Dashboard</span></a></li>
        <li class="sidebar-list-item">
          <a [ngClass]="{'act': customerAccountChild1RLA.isActive || customerAccountChild2RLA.isActive}" style="cursor: pointer;" data-toggle="collapse" data-target="#customerAccount" class="sidebar-link text-muted"><i class="fa fa-users mr-3"></i><span>Customers Account </span><span class="fa fa-angle-down ml-3" ></span></a>
        </li>
        <div class="collapse px-3" id="customerAccount">
          <ul class="">
            <li class="sidebar-list-item">
              <a routerLink="/all-customers" #customerAccountChild1RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Search for Customers</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/create-customer" #customerAccountChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Create Customer</span></a>
            </li>
            
          </ul>
        </div>
        <!-- <li class="sidebar-list-item"><a routerLink="/loans" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-money-bill mr-3"></i><span>Personal Loans</span></a></li> -->
        <li class="sidebar-list-item">
          <a [ngClass]="{'act': personalLoansChild1RLA.isActive || personalLoansChild2RLA.isActive || personalLoansChild3RLA.isActive|| personalLoansChild4RLA.isActive|| personalLoansChild5RLA.isActive|| personalLoansChild6RLA.isActive|| personalLoansChild7RLA.isActive|| personalLoansChild8RLA.isActive|| personalLoansChild9RLA.isActive|| personalLoansChild10RLA.isActive|| personalLoansChild11RLA.isActive}" style="cursor: pointer;" data-toggle="collapse" data-target="#personalLoans" class="sidebar-link text-muted"><i class="fa fa-credit-card mr-3"></i><span>Personal Loans </span><span class="fa fa-angle-down ml-3" ></span></a>
        </li>
        <div class="collapse px-3" id="personalLoans">
          <ul class="">
            <li class="sidebar-list-item">
              <a [routerLink]="['/loans/0']" #personalLoansChild1RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span> Loans</span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/1']" #personalLoansChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/2']" #personalLoansChild3RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/3']" #personalLoansChild4RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/4']" #personalLoansChild5RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/5']" #personalLoansChild6RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/6']" #personalLoansChild7RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/7']" #personalLoansChild8RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/8']" #personalLoansChild9RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/9']" #personalLoansChild10RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            <li class="some-hidden-class">
              <a [routerLink]="['/loans/-1']" #personalLoansChild11RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><span></span></a>
            </li>
            
            <!-- <li class="sidebar-list-item">
              <a #personalLoansChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Loans</span></a>
            </li> -->
          </ul>
          <li class="sidebar-list-item">
            <a routerLink="/waive-default" #customerAccountChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Waive Default</span></a>
          </li>
        </div>
        <li class="sidebar-list-item">
          <a [ngClass]="{'act': collateralLoanChild1RLA.isActive || collateralLoanChild2RLA.isActive || collateralLoanChild3RLA.isActive || collateralLoanChild4RLA.isActive}" style="cursor: pointer;" data-toggle="collapse" data-target="#collateralLoan" class="sidebar-link text-muted"><i class="fa fa-area-chart mr-3"></i><span>Collateral Loan </span><span class="fa fa-angle-down ml-3" ></span></a>
        </li>
        <div class="collapse px-3" id="collateralLoan">
          <ul class="">
            <li class="sidebar-list-item">
              <a routerLink="/collateral-loan/loans" #collateralLoanChild1RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Loans</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/collateral-loan/categories" #collateralLoanChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Categories</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/collateral-loan/rate" #collateralLoanChild3RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Rates</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/collateral-loan/agent" #collateralLoanChild4RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Agents</span></a>
            </li>
          </ul>
        </div>
        <li class="sidebar-list-item">
          <a [ngClass]="{'act': investmentsChild1RLA.isActive || investmentsChild2RLA.isActive || investmentsChild3RLA.isActive || investmentsChild4RLA.isActive || investmentsChild5RLA.isActive || investmentsChild6RLA.isActive || investmentsChild7RLA.isActive}" style="cursor: pointer;" data-toggle="collapse" data-target="#investments" class="sidebar-link text-muted"><i class="fa fa-line-chart  mr-3"></i><span>Investments</span><span class="fa fa-angle-down ml-3" ></span></a>
        </li>
        <div class="collapse px-3" id="investments">
          <ul class="">
            <li class="sidebar-list-item"><a routerLink="/investments/create-investment" #investmentsChild1RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-outdent mr-3"></i><span>Create Investment</span></a></li>
            <li class="sidebar-list-item">
              <a routerLink="/investments/0" #investmentsChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-outdent mr-3"></i><span>Pending Approval</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/investments/2" #investmentsChild3RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Active</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/investments/5" #investmentsChild4RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Completed</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/investments/3" #investmentsChild5RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Canceled</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/investments/manage" #investmentsChild6RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Investments Mgmt.</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/investments/team" #investmentsChild7RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Investments Team</span></a>
            </li>
          </ul>
        </div>
        <li class="sidebar-list-item">
          <a [ngClass]="{'act': reportsChild1RLA.isActive || reportsChild2RLA.isActive || reportsChild3RLA.isActive|| reportsChild4RLA.isActive|| reportsChild5RLA.isActive|| reportsChild6RLA.isActive|| reportsChild7RLA.isActive}" style="cursor: pointer;" data-toggle="collapse" data-target="#reports" class="sidebar-link text-muted"><i class="fa fa-book mr-3"></i><span>Reports</span><span class="fa fa-angle-down ml-3" ></span></a>
        </li>
        <div class="collapse px-3" id="reports">
          <ul class="">
            <li class="sidebar-list-item">
              <a routerLink="/report/loans" #reportsChild1RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Loans Report</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/report/active-loans" #reportsChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Active Loans Report</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/report/users-signup" #reportsChild3RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Users Signup Report</span></a>
            </li>
  <!--           
            <li class="sidebar-list-item">
              <a routerLink="/report/disbursement" #reportsChild2RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Disbursement Report</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/report/defaults" #reportsChild3RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Defaults Report</span></a>
            </li> -->
            <li class="sidebar-list-item">
              <a routerLink="/report/repayment" #reportsChild4RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Repayment Report</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/report/defaults" #reportsChild5RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Defaults Report</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/report/due-repayment" #reportsChild6RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Direct Debit</span></a>
            </li>
            <li class="sidebar-list-item">
              <a routerLink="/report/transaction-journal" #reportsChild7RLA="routerLinkActive" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-list mr-3"></i><span>Transaction Journal</span></a>
            </li>
          </ul>
        </div>
        <!-- <li class="sidebar-list-item"><a routerLink="/users" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-users mr-3"></i><span>Users</span></a></li> -->
        <!-- <li class="sidebar-list-item"><a routerLink="/admins" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-cogs mr-3"></i><span>Users Management</span></a></li> -->
      </ul>
      <ul class="sidebar-menu list-unstyled d-none d-sm-block">
        <li class="sidebar-heading my-2">
          Finance
        </li>
        <li class="sidebar-list-item"><a routerLink="/chart" routerLinkActive="active" class="sidebar-link text-muted">
          <i class="fa fa-pie-chart mr-3">
          </i><span>Chart of accounts</span></a>
        </li>
        <li class="sidebar-list-item"><a routerLink="/funds-transfer" routerLinkActive="active" class="sidebar-link text-muted">
          <i class="fa fa-exchange mr-3">
          </i><span>Funds Transfer</span></a>
        </li>
        <li class="sidebar-list-item"><a routerLink="/account-enquiry" routerLinkActive="active" class="sidebar-link text-muted">
          <i class="fa fa-server mr-3">
          </i><span>Account Enquiry</span></a>
        </li>
        <!-- <li class="sidebar-list-item"><a routerLink="/users" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-users mr-3"></i><span>Users</span></a></li> -->
        <!-- <li class="sidebar-list-item"><a routerLink="/admins" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-cogs mr-3"></i><span>Users Management</span></a></li> -->
      </ul>
      <!-- <div class="text-blue-badge text-uppercase px-3 px-lg-3 py-1 font-weight-bold small headings-font-family">EXTRAS</div> -->
      <!-- Close sidebar when item is clicked in smaller screens -->
        <ul class="sidebar-menu list-unstyled d-block d-sm-none">
          <!-- <li class="sidebar-list-item"><a closeSidebar routerLink="/profile" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-user mr-3"></i><span>Profile</span></a></li> -->
          <li class="sidebar-list-item"><a closeSidebar routerLink="/admins" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-users mr-3"></i><span>Users Management</span></a></li>
          <li class="sidebar-list-item"><a closeSidebar routerLink="/change-password" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-cogs mr-3"></i><span>Change Password</span></a></li>
        </ul>
    
        <!--Do not close sidebar when item is clicked in smaller screens -->
        <ul class="sidebar-menu list-unstyled d-none d-sm-block">
          <li class="sidebar-heading my-2">
            Utilities
          </li>
            <li class="sidebar-list-item"><a routerLink="/utilities/upload-loans" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-upload mr-3"></i><span>Upload Loan Records</span></a></li>
            <li class="sidebar-list-item"><a routerLink="/utilities/calculate-repayment" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-calculator mr-3"></i><span>Calculate Repayment</span></a></li>
            <li class="sidebar-list-item"><a routerLink="/utilities/add-loan" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-calculator mr-3"></i><span>Create Loan Account</span></a></li>
            
            <li class="sidebar-list-item"><a routerLink="/utilities/loan-products" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-calculator mr-3"></i><span>Loan Products</span></a></li>
            <li class="sidebar-list-item"><a routerLink="/utilities/offer-letter" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-calculator mr-3"></i><span>Generate Offer Letter</span></a></li>
          </ul>
      <ul class="sidebar-menu list-unstyled d-none d-sm-block">
        <li class="sidebar-heading my-2">
          Extra
        </li>
          <li class="sidebar-list-item"><a routerLink="/admins" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-users mr-3"></i><span>Users Management</span></a></li>
          <li class="sidebar-list-item"><a routerLink="/change-password" routerLinkActive="active" class="sidebar-link text-muted"><i class="fa fa-cogs mr-3"></i><span>Change Password</span></a></li>
          <li style="cursor: pointer;" (click)="logOut()" class="sidebar-list-item"><a class="sidebar-link text-muted"><i class="fa fa-reply-all mr-3"></i><span>Log Out</span></a></li>
        </ul>
    </div>
  </div>
</div>
