import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { TokenInterceptor } from './token-interceptor';
import { ChartComponent } from './chart/chart.component';
import { DialogComponent } from './dialog/dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WaiveDefaultComponent } from './waive-default/waive-default.component';
import { DueRepaymentReportComponent } from './due-repayment-report/due-repayment-report.component';
import { AccountEnquiryComponent } from './account-enquiry/account-enquiry.component';
import { InvestmentsTeamComponent } from './investments-team/investments-team.component';
import { TransactionJournalComponent } from './transaction-journal/transaction-journal.component';

const ngxUiConfig: NgxUiLoaderConfig = {
  bgsColor: '#dd4f05',
  bgsType: 'rectangle-bounce',
   fgsColor: '#dd4f05',
  fgsType: 'rectangle-bounce',
  text: 'Please wait ...',
  textColor: '#dd4f05',
  pbColor: '#dd4f05'
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeLayoutComponent,
    SidebarComponent,
    ChartComponent,
    DialogComponent,
    WaiveDefaultComponent,
    DueRepaymentReportComponent,
    AccountEnquiryComponent,
    InvestmentsTeamComponent,
    TransactionJournalComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule, // (Required) for share counts
    HttpClientJsonpModule, // (Optional) For linkedIn & Tumblr counts
    SidebarModule.forRoot(),
    NgbCollapseModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot({
    }),
    NgxUiLoaderModule.forRoot(ngxUiConfig),
    LoadingBarModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    AppRoutingModule,
    
  ],
  providers: [
    // AppServiceService,
    {
      provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
