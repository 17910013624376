<br>
<br>
<!-- Loan Section -->
<div class="section no-padding" style="height: auto; background-color: none !important;">
  <div  class="container-fluid content">
    <div class="col-md-12 col-sm-12">
      <div class="card stats-card" style="border-radius: 10px;">
        <div class="card-body" style="min-height: 200px; position: relative;">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="text-primary m-0"><strong>Investment Team</strong></h5>
            <span class="d-inline-flex">
                <button class="btn btn-primary" data-toggle="modal" data-target="#newLoanProduct" type="button">Create Investment Team</button>
            </span>
          </div>
          <hr>
          <div class="row mt-2 mb-4 justify-content-between">
            <div class="col-md-auto">
                
            </div>
            <div class="col-sm-12 col-md-4">
              <div>
                <p class="text-sm form-inline">Show &nbsp;&nbsp;
                  <select class="custom-select custom-select-sm" [(ngModel)]="page_size" (change)="pageSizeChanged()">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select> &nbsp;&nbsp; entries
                </p>
              </div>
            </div>
          </div>
          <div class="text-center" *ngIf="loading" class="loading">
            <img src="assets/imgs/loading.gif" height="100">
          </div>

          <span>
            <div *ngIf="investmentTeamlists?.length > 0">
                <div class="table-responsive">
                    <table class="table table-bordered table-striped" style="min-height: 30vh;">
                      <thead class="text-sm">
                        <tr class="text-blue-badge">
                            <th>
                              S/N
                            </th>
                          <th>Team Lead</th>
                          <th>
                            Team Member
                          </th>
                        </tr>
                      </thead>
                      <tbody class="text-sm">
                        <tr *ngFor="let investmentTeamlist of investmentTeamlists; let index = index">
                            <td>{{index + 1}}</td>
                            <td>{{investmentTeamlist.team_lead?.first_name | titlecase}} {{investmentTeamlist.team_lead?.last_name | titlecase }}</td>
                            <td>
                                <ul *ngFor="let subordinate of investmentTeamlist.subordinates; let index = index" >
                                    <li> {{index + 1}}. {{subordinate?.first_name | titlecase}} {{subordinate?.last_name | titlecase }}</li>
                                </ul>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                <br>
                <div class="float-right">
                    <button (click)="goPrevious()" class="btn shadow  btn-sm">
                        <i class="fa fa-angle-double-left"></i>&nbsp;&nbsp;Previous
                    </button>
                    <button (click)="goNext()" class="btn shadow ml-4 btn-sm">
                        Next &nbsp;&nbsp;<i class="fa fa-angle-double-right"></i>
                    </button>
                </div>
            </div>
            <div *ngIf="investmentTeamlists?.length == 0" style="margin-top: 40px; margin-bottom: 40px;">
                <p class="text-center text-blue-black"><b>No data available</b></p>
            </div>
        </span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Loan section ends here -->

<div class="modal fade" id="newLoanProduct" data-dismiss="false" data-backdrop="static" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="text-primary m-0">New Investment Team</h5>
                <button type="button" (click)="resetModal()" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="form-group" [formGroup]="newInvestmentTeam">
                    <div class="mb-3">
                        <label class="text-blue-badge text-muted">Team Lead</label>
                        <select formControlName="team_lead_id" [disabled]="disabledSelect" style="flex: 1;" class="custom-select custom-select-sm" (change)="pageSizeChanged()">
                            <option disabled [value]="null" selected>Select staff</option>
                            <option value="" selected>All investments</option>
                            <option *ngFor="let user of investmentUsers" [value]="user?.id">{{user?.first_name | titlecase}} {{user?.last_name | titlecase}}</option>
                          </select>
                    </div>
                    <div class="mb-3">
                        <label class="text-blue-badge text-muted">Subordinates</label>
                        <select multiple="multiple" formControlName="subordinates" [disabled]="disabledSelect" style="flex: 1;" class="custom-select custom-select-sm" (change)="pageSizeChanged()">
                            <option *ngFor="let user of investmentUsers" [value]="user?.id">{{user?.first_name | titlecase}} {{user?.last_name | titlecase}}</option>
                          </select>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                    (click)="resetModal()">Close</button>
                <button type="button" class="btn btn-primary" (click)="createInvestmentTeam()">Submit</button>
            </div>
        </div>
    </div>
</div>
