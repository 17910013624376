<div class="container-fluid main">
    <h4 class="text-center">Add A New GL</h4>
    <div class="form">
        <form [formGroup]="createGl" (ngSubmit)="add()">
            <div class="form-group">
              <label class="form-label">GL Name</label>
              <input 
                    type="text" 
                    class="form-control" 
                    placeholder="Enter GL Name" 
                    formControlName="name"
                    [class.is-invalid]="createGl.get('name').invalid && createGl.get('name').touched"
                >
                <div *ngIf="createGl.get('name').valid || createGl.get('name').touched" class="text-danger">
                  <small *ngIf="createGl.get('name').errors?.required" class="text-danger">Ledger Name is required</small>
                </div>
            </div>
            <div class="form-group">
              <label for="number">Create Account Number</label>
              <input 
                type="text" 
                class="form-control" 
                placeholder="Ex- A000000000" 
                formControlName="number"
                [class.is-invalid]="createGl.get('number').invalid &&
                createGl.get('number').touched"
                >
                <div *ngIf="createGl.get('number').valid || createGl.get('number').touched" class="text-danger">
                  <small *ngIf="createGl.get('number').errors?.required" class="text-danger">Account Number is required</small>
                </div>
            </div>
            <div class="form-group">
                <label for="category">Select category</label>
                <select 
                class="form-control" 
                formControlName="category"
               [class.is-invalid]="createGl.get('category').invalid && 
                createGl.get('category').touched"
              >
                  <option value="" selected>-Select-</option>
                  <!-- <option *ngFor="let categorie of categories">{{ categorie }}</option> -->
                  <option value="asset">Asset</option>
                  <option value="income">Income</option>
                  <option value="liability">Liability</option>
                  <option value="expenditure">Expenditure</option>
                </select>
                <div *ngIf="createGl.get('category').valid || createGl.get('category').touched" class="text-danger">
                  <small *ngIf="createGl.get('category').errors?.required" class="text-danger">Choose a category</small>
                </div>
              </div>
            <button type="submit" value="submit" class="btn btn-primary submit">Add GL</button>
          </form>
    </div>
</div>