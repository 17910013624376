<nav  class="navbar header-bg navbar-expand ">
  <!-- <div class="navbar-translate">
      <a class="navbar-brand" routerLink="/"><img src="assets/imgs/logo.png"></a>
    </div> -->
  <button class="btn border " type="button" (click)="toggleSidebar()" >
      <span>
          <i class="fa fa-bars text-tertiary"></i>
      </span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav">
    </ul>

    <ul class="navbar-nav ml-auto">

        <li class="nav-item" *ngIf="auth">
          <div class="d-inline-block" ngbDropdown display="dynamic" placement="bottom-right">
            <!-- <button ngbDropdownToggle id="dropdownNavbarProfile" class="btn border text-sm">
              <img class="" [src]="auth.user.display_picture ? auth.user.display_picture : 'assets/imgs/user.jpg'" alt="User picture" style="border-radius: .35rem !important;" width="35" height="35">
              
            </button> -->
            <div ngbDropdownMenu aria-labelledby="dropdownNavbarProfile">
              <div class="px-4 py-2">
                <span class="text-white text-center text--bold text--medium text-xl">
                  <b>Welcome back, {{login.first_name | titlecase}}</b>
                  <p class="text-tertiary text-sm">It is good to have you here</p>
                </span>
                <!-- <span class="text-xs">{{login.department}}</span> -->
              </div>
              <div style="background-color:#252A58" class="dropdown-divider m-0"></div>
              <!-- <a ngbDropdownItem routerLink="/profile" class="text-sm py-2 text-gray">Profile</a> -->
              <!-- <div class="dropdown-divider m-0"></div>
              <button ngbDropdownItem class="text-sm py-2 text-gray" (click)="logout()">Logout</button> -->
            </div>
          </div>
        </li>
        
      </ul>
      <td>
        <div class="dropdown">
          <button class=" btn text-sm text-white dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          </button>
          <div style="margin-left: -95px;" class="dropdown-menu "  aria-labelledby="dropdownMenuButton">
            <!-- <a class="" href="#">Action</a> -->
            <button (click)="logout()" class="dropdown-item text-center text-danger">Logout</button>
          </div>
        </div>
      </td>
  </div>
</nav>
  