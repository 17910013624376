import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppServiceService } from './app-service.service';
import { IdleService } from './idle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Money Marque';
  constructor(private router: Router,private service: AppServiceService,private toastr: ToastrService, private idleService: IdleService) {
  }
  ngOnInit(): void {
    this.initialIdleSettings();
  }
  private initialIdleSettings() {
    const idleTimeoutInSeconds: number = environment.idleTimeInMinutes * 1800;
    this.idleService.startWatching(idleTimeoutInSeconds).subscribe((isTimeOut: boolean) => {
      if (isTimeOut) {
        // alert("Session timeout. It will redirect to login page.");
        this.toastr.error('Session timeout. Kindly login.');
        return this.logOut() ;
      }
    });
  }
  logOut() {
    this.service.clearStorage().then(() => this.router.navigate(['/account/login']));
  }
}


