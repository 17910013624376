import { Component, OnInit } from '@angular/core';
import { AppServiceService } from '../app-service.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

declare const $: any;

@Component({
  selector: 'app-due-repayment-report',
  templateUrl: './due-repayment-report.component.html',
  styleUrls: ['./due-repayment-report.component.scss']
})
export class DueRepaymentReportComponent implements OnInit {

  status = '';
  date_from = '';
  date_to = '';
  page_size = 10;
  loading: boolean;
  reports: any;
  reportsData: any;
  pay_date: any;
  itemID: any;
  repayment_amount: any;
  constructor(private service: AppServiceService, private toastr: ToastrService, titleService: Title) {
    titleService.setTitle('Due Repayment Report');
   }

  ngOnInit(): void {
  }

  pageSizeChanged() {
    this.getRepaymentReport();
  }
  getRepaymentReport() {
    this.loading = true;
    const params = {
      start: this.date_from,
      end: this.date_to,
      status: this.status,
    };
    this.service.dueRepaymentReport(params).subscribe(
      data => {
        this.loading = false;
        const result: any = data;
        if (result.status === '00') {
            this.reportsData = result;
          this.reports = result.data;
          console.log(this.reports)
        } else {
          this.toastr.success(result['message'], '');
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

  // goNext() {
  //   if (!this.reportsData.next_page_url) {
  //     // this.toastr.info('You are on the last page already');
  //     this.toastr.info('You are on the last page already');
  //   } else {
  //     const json = {
  //       search_text: this.searchText,
  //       page_size: this.page_size,
  //     start: this.date_from,
  //     end: this.date_to,
  //     download: '0'
  //     };
  //     console.log(json)
  //     this.service.fetchMoreRecords(this.reportsData.next_page_url, json).subscribe((data: any) => {
  //       console.log(data);
  //       if (data.status === 'success') {
  //         this.reportsData = data.report;
  //         this.reports = data.report.data;
  //       } else {

  //       }
  //     });
  //   }
  // }

  // goPrevious() {
  //   if (!this.reportsData.prev_page_url) {
  //     this.toastr.info('You are on the first page already');
  //   } else {
  //     const json = {
  //       search_text: this.searchText,
  //       page_size: this.page_size,
  //     start: this.date_from,
  //     end: this.date_to,
  //     download: '0'
  //     };
  //     this.service.fetchMoreRecords(this.reportsData.prev_page_url, json).subscribe((data: any) => {
  //       console.log(data);
  //       if (data.status === 'success') {
  //         this.reportsData = data.report;
  //         this.reports = data.report.data;
  //       } else {

  //       }
  //     });
  //   }
  // }
  setRepaymentObject(id, amount) {
    this.itemID = id;
    this.repayment_amount = amount;
  }

  // confirmRepayment() {
  //   if (confirm('Are you sure you want to confirm this repayment?')) {
  //     const obj = {
  //       id: this.itemID,
  //       date: this.pay_date
  //     };
  //     this.service.confirmRepayment(obj).subscribe(
  //       data => {
  //         this.loading = false;
  //         const result: any = data;
  //         if (result.status === 'success') {
  //           this.toastr.success(result['message']);
  //           $('#confirmRepaymentModal').modal('hide');
  //           this.getRepaymentReport();
  //         } else {
  //           this.toastr.error(result['message'], '');
  //         }
  //       },

  //     );
  //   }
  // }

  confirmRepayment() {
    if (this.pay_date) {
      if (confirm('Are you sure you want to confirm this repayment?')) {
            const obj = {
              loan_id: this.itemID,
              date: this.pay_date,
              amount: this.repayment_amount
            };
            this.service.confirmRepayment(obj).subscribe(
              data => {
                this.loading = false;
                const result: any = data;
                if (result.status === 'success') {
                  this.toastr.success(result['message']);
                  $('#confirmRepaymentModal').modal('hide');
                  this.getRepaymentReport();
                } else {
                  this.toastr.error(result['message'], '');
                }
              },
            );
          }
     } else {
       this.toastr.error('Please fill form');
     }
  }

  deleteRepayment(id) {
    if (confirm('Are you sure you want to delete this repayment?')) {
      const obj = {
        id
      };
      this.service.deleteRepayment(obj).subscribe(
        data => {
          this.loading = false;
          const result: any = data;
          if (result.status === 'success') {
            this.toastr.success(result['message']);
            this.getRepaymentReport();
          } else {
            this.toastr.error(result['message'], '');
          }
        },

      );
    }
  }
  resetModal() {
    this.pay_date = null;
    this.repayment_amount = null;
  }
}
