import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppServiceService } from 'src/app/app-service.service';
declare const $: any;
@Component({
  selector: 'app-investments-team',
  templateUrl: './investments-team.component.html',
  styleUrls: ['./investments-team.component.scss']
})
export class InvestmentsTeamComponent implements OnInit {

  searchText = ''
  page_size = 50;
  disabledSelect = false;
  investmentUsers: any[];
  investmentTeamlists: Array<any> = [[]];
  loading = false;
  destroy$ = new Subject<boolean>();
  newInvestmentTeam: FormGroup;
  constructor(private toastr: ToastrService, private fb: FormBuilder, private title: Title, private service: AppServiceService) {
    this.newInvestmentTeam = this.buildForm();
  }

  ngOnInit(): void {
    this.getInvestmentStaff();
    this.getInvestmentTeamlist();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  buildForm(): FormGroup {
    return this.fb.group({
      team_lead_id: ['', Validators.required],
      subordinates : [[''], Validators.required],
    })
  }

  get formControls() {
    return this.newInvestmentTeam.controls
  }

  getInvestmentTeamlist() {
    this.loading = true;
    this.service.getInvestmentTeamlist().pipe(takeUntil(this.destroy$)).subscribe({
      next: (data: any) => {
        console.log(data);
        this.investmentTeamlists = data.data;
        console.log(this.investmentTeamlists)
        this.loading = false;
      },
      error: () => this.loading = false
    })
  }

  pageSizeChanged() {

  }

  createInvestmentTeam() {
    if (this.newInvestmentTeam.valid) {
      this.newInvestmentTeam.disable();
      this.service.createInvestmentTeam(this.newInvestmentTeam.value).pipe(takeUntil(this.destroy$)).subscribe({
        next: (data: any) => {
          this.newInvestmentTeam.enable();
          this.toastr.success(data.message);
          this.newInvestmentTeam.reset();
          $('#newLoanProduct').modal('hide');
          this.getInvestmentTeamlist();
        },
        error: () => {
          this.newInvestmentTeam.enable();
        }
      })
    } else {
      Object.values(this.formControls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true })
        }
      })
    }
  }

  resetModal() {
    $('#newLoanProduct').modal('hide');
    this.newInvestmentTeam.reset();
  }

  getInvestmentStaff() {
    this.disabledSelect = true;
    this.service.getInvestmentStaff().subscribe(
      (data: any) => {
        this.investmentUsers = data.data;
        console.log(this.investmentUsers)
        this.disabledSelect = false;
      },
      error => {
        this.disabledSelect = false;
      }
    );
  }

}
