import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-transaction-journal',
  templateUrl: './transaction-journal.component.html',
  styleUrls: ['./transaction-journal.component.scss']
})
export class TransactionJournalComponent implements OnInit {
  date_from = '';
  date_to = '';
  isLoading: boolean;

  constructor( private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  downlaodLoneList(){
    if (this.date_to){
      window.open(`https://api.moneymarqueng.com/reports/transaction-journal?start_date=${this.date_from}&end_date=${this.date_to}`)
      this.toastr.success('downloading');
    } else{
      this.toastr.error('Error connecting to server, please check your internet connection and try again');
      console.log()
    }
  }

}
