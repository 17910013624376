import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppServiceService } from 'src/app/app-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  auth: any;
  constructor(private router: Router, private service: AppServiceService) { 
    this.auth = this.service.getAuth();
    // $('.toggle-btn').on('click', function() {
    //   $(this).toggleClass('active')
    // });
  }

  ngOnInit() {
  }

  logOut() {
    this.service.clearStorage().then(() => this.router.navigate(['/account/login']));
  }
  
}

