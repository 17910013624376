<br>
<br>
<!-- Loan Section -->
<div class="section no-padding" style="height: auto; background-color: none !important;">
    <div class="container-fluid content">
        <div class="col-md-12 col-sm-12">
            <div class="card stats-card" style="border-radius: 10px;">
                <div class="card-body" style="min-height: 200px; position: relative;">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <h5 class="text-primary m-0"><strong>Transaction Journal</strong></h5>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col">
                            <div>
                                <label class="text-muted" for="">Start Date</label>
                                <input type="date" class="form-control form-control-sm" name="start"
                                    [(ngModel)]="date_from">
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <label class="text-muted" for="">End Date</label>
                                <input type="date" class="form-control form-control-sm" name="start"
                                    [(ngModel)]="date_to">
                            </div>
                        </div>
                    </div>
                    <div class="row my-4">
                        <div class="col-sm-12 col-md-4">
                            <div>
                                <!-- <p class="text-sm form-inline">Show &nbsp;&nbsp;
                                    <select class="custom-select custom-select-sm" [(ngModel)]="page_size"
                                        (change)="pageSizeChanged()">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                    </select> &nbsp;&nbsp; entries
                                </p> -->
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8">
                            <div class="float-right">
                                <!-- <button class="btn btn-primary mr-1" (click)="getRepaymentReport()">Export</button> -->
                                <button class="btn btn-primary" (click)="downlaodLoneList()">Export</button>
                            </div>
                        </div>
                    </div>
                    <div class="text-center" *ngIf="isLoading" class="loading">
                        <img src="assets/imgs/loading.gif" height="100">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
