// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiEndpoint: 'http://127.0.0.1:8000/api/'
  apiEndpoint: 'https://api.moneymarqueng.com/api/',
  idleTimeInMinutes: 5
};

export const emailRegex = '^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}$';
export const phoneRegex = '^[0]+[0-9]{10}$';
export const bvnRegex = '^[0-9]{11}$';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
` `
