<div class="section" style="height: auto; background-color: none !important;">
    <div class="container-fluid content">
      <div class="col-md-12 col-sm-12">
        <div class="card stats-card" style="border-radius: 10px;">
          <div class="card-body" style="min-height: 200px; position: relative;">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <h5 class="text-primary m-0"><strong>Waive Default</strong></h5>
              <span class="d-inline-flex">
                <span class="text-sm form-inline d-inline-block"><!-- Search: &nbsp;&nbsp;  -->
                </span>
              </span>
            </div>
            <hr>
            <div class="text-center" *ngIf="loading" class="loading">
              <img src="assets/imgs/loading.gif" height="100">
            </div>
  
            <span *ngIf="repayments">
              <div *ngIf="repayments.length > 0" >
                <table class="table text-primary table-bordered">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Name</th>
                            <th scope="col">Loan Amount</th>
                            <th scope="col">Repayment Amount</th>
                            <th scope="col">Default Amount</th>
                            <th scope="col">Payment Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Approval</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngIf="repayments && repayments.length; else norepayments">
                            <tr *ngFor="let repayment of repayments; let index = index">
                                <td>
                                    {{index + 1}}
                                </td>
                                <td scope="row">
                                    {{repayment.user?.firstname}} {{repayment.user?.lastname}}
                                </td>
                                <td  scope="row">
                                    {{repayment.loan?.loan_amount ? (repayment.loan?.loan_amount | currency: '₦') : 'None'}}
                                </td>
                                <td scope="row">
                                    {{repayment.repayment_amount ? (repayment.repayment_amount | currency: '₦') : 'None'}}
                                </td>

                                <td scope="row">
                                    {{repayment.default_amount ? (repayment.default_amount | currency: '₦') : 'None'}}
                                </td>
                                <td scope="row">
                                    {{repayment.payment_date? (repayment.payment_date | date) : 'None'}}
                                </td>

                                <!-- <td scope="row">
                                    {{repayment.due_date   ?  (repayment.due_date| date) : 'None'}}
                                </td> -->
                                <td>
                                    <span *ngIf="repayment.status == 0" class="badge badge-secondary">Not Paid</span>
                                    <span *ngIf="repayment.status == 1" class="badge badge-primary">Half Paid</span>
                                    <span *ngIf="repayment.status == 2" class="badge badge-success">Fully Paid</span>
                                    <span *ngIf="repayment.status == 3" class="badge badge-info">Overpaid</span>
                                    <span *ngIf="repayment.status == 4" class="badge badge-dark">Refund</span>
                                </td>
                                <td>
                                    <ng-container *ngIf="repayment.status >= 1">
                                        <span class="text-blue-badge">{{repayment.payment_date | date}}</span>
                                    <br>
                                    </ng-container>
                                    <div *ngIf="repayment.status == 0 || repayment.status == 1">
                                        <button class="btn btn-secondary mr-1 btn-small" data-toggle="modal"
                                        data-target="#approveWaiverModal" (click)="setApproveWaiverObject(repayment.id, repayment.repayment_amount,repayment.default_amount,repayment.loan.loan_id, repayment.repayment_id)">Approve</button>
                                        <button data-toggle="modal" data-target="#declineWaiverModal" class="btn btn-danger btn-small" (click)="setDeclineWaiverObject(repayment.id)" >Decline</button>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-template #norepayments>
                            <tr>
                                <td colspan="6">
                                    <div class="text-center p-3">
                                        No repayment
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
                  <div class="float-right">
                    <button (click)="goPrevious()" class="btn shadow btn-sm">
                      <i class="fa fa-angle-double-left"></i>&nbsp;&nbsp;Previous
                    </button>
                    <button (click)="goNext()" class="btn shadow ml-4 btn-sm">
                      Next &nbsp;&nbsp;<i class="fa fa-angle-double-right"></i>
                    </button>
                  </div>
              </div>
              <div *ngIf="repayments.length == 0" style="margin-top: 40px; margin-bottom: 40px;">
                <p class="text-center text-blue-black"><b>No data available</b></p>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="approveWaiverModal" data-backdrop="static" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Approve Waiver</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form  action="">
                    <div class="mb-3">
                        <label class="form-label text-muted">Amount</label>
                        <input readonly type="number" name="repayment_amount" [(ngModel)]="repayment_amount" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label class="form-label text-muted">Default Amount</label>
                        <input type="number" name="defaultAmount" [(ngModel)]="defaultAmount" class="form-control">
                    </div>
                    <div class="mb-3">
                        <label class="form-label text-muted">Date</label>
                        <input type="date" name="pay_date" [(ngModel)]="pay_date" class="  form-control">
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="approveWaiver()">Confirm</button>
            </div>
        </div>
    </div>
</div>
  <div class="modal fade" id="declineWaiverModal" data-backdrop="static" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Decline Waiver</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="">
                    <div class="mb-3">
                        <label class="form-label text-muted">Reason</label>
                        <textarea rows="4" type="number" name="repayment_amount" [(ngModel)]="reason" class="form-control">
                        </textarea>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary" (click)="declineWaiver()">Confirm</button>
            </div>
        </div>
    </div>
</div>
