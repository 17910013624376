import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppServiceService } from '../app-service.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
declare const $: any;
@Component({
  selector: 'app-waive-default',
  templateUrl: './waive-default.component.html',
  styleUrls: ['./waive-default.component.scss']
})
export class WaiveDefaultComponent implements OnInit {

  loanId: any;
  waiverId: any;
  loading: any;
  defaultAmount:any;
  page = 1;
  reason:any;
  isChecked : boolean = false;
  approve :boolean = true;
  repayment_amount: any;
  repaymentId: any;
  page_size = 10;
  status: any = 0;
  statu: any = 0;
  nullstatus: any = null;
  searchText = '';
  date_from = '';
  repayments: any[];
  pay_date: any;
  date_to = '';
  prev: any;
  next: any;
  loansData: any;
  constructor(private router: Router, private service: AppServiceService, private toastr: ToastrService, private titleService: Title,private activatedRoute: ActivatedRoute) {
    this.titleService.setTitle('Loans');
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((routeParam: Params) => {
      this.loanId = routeParam.id;
      this.waiveDefault();
    });
    
  }

  setApproveWaiverObject(id:any, repayment_amount:any,default_amount:any,loan_id:any,repayment_id:any) {
    this.waiverId = id;
    this.repayment_amount = repayment_amount;
    this.defaultAmount = default_amount;
    this.loanId = loan_id;
    this.repaymentId = repayment_id;
    // this.default =(this.repayment_amount - this.repayment_amount_paid) + this.defaultAmount
  }
  setDeclineWaiverObject(id:any) {
    this.waiverId = id;
  }


  waiveDefault(loading = true) {
    this.loading = loading;
    this.service.waiveDefault().subscribe((data: any) => {
      this.loading = false;
      if (data.status === 'success') {
        this.repayments = data.data;
        // this.newApplication = data.loan[1];
        console.log(this.defaultAmount);
      }
    }, err => {
      this.loading = false;
    })
  }

  approveWaiver() {
    if (this.pay_date) {
      if (confirm('Are you sure you want to approve this waiver?')) {
            const obj = {
              loan_id: this.loanId,
              date: this.pay_date,
              amount: this.repayment_amount,
              default_amount:this.defaultAmount,
              waive_default:this.isChecked,
              approve_waiver:this.approve,
              waiver_id:this.waiverId
            };
            this.service.approveWaiver(obj).subscribe(
              data => {
                this.loading = false;
                const result: any = data;
                if (result.status === 'success') {
                  this.toastr.success(result['message']);
                  $('#approveWaiverModal').modal('hide');
                  this.waiveDefault(false);
                } else {
                  this.toastr.error(result['message'], '');
                }
              },
            );
      }
     } else {
       this.toastr.error('Please fill form');
     }
    }

    declineWaiver() {
    if (confirm('Are you sure you want to decline this waiver?')) {
            const obj = {
              comment:this.reason,
              waiver_id:this.waiverId
            };
            this.service.declineWaiver(obj).subscribe(
              data => {
                this.loading = false;
                const result: any = data;
                if (result.status === 'success') {
                  this.toastr.success(result['message']);
                  $('#declineWaiverModal').modal('hide');
                  this.waiveDefault(false);
                } else {
                  this.toastr.error(result['message'], '');
                }
              },
            );
     } 
  }

}
